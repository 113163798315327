import { createContext, useContext, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";

type ContextValue = {
  setShouldSwap?: Dispatch<SetStateAction<boolean>>;
  shouldSwap: boolean;
};

const AppBarContext = createContext<ContextValue>({
  shouldSwap: false,
});

type Props = {
  children: ReactNode;
};

export const AppBarContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [shouldSwap, setShouldSwap] = useState(false);
  return (
    <AppBarContext.Provider value={{ setShouldSwap, shouldSwap }}>
      {children}
    </AppBarContext.Provider>
  );
};

AppBarContextProvider.displayName = "AppBarContextProvider";

export const useAppBarContext = () => useContext(AppBarContext);
