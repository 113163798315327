import dynamic from "next/dynamic";

import type { Props as HeaderProps } from "scmp-app/components/header";
import type { Props as HomeHeaderProps } from "scmp-app/components/home/header";
import type { Props as PlusHeaderProps } from "scmp-app/components/plus/header";
import type { Props as PostiesBrochureHeaderProps } from "scmp-app/components/posties/header/posties-brochure-header";
import type { Props as PostiesContentHeaderProps } from "scmp-app/components/posties/header/posties-content-header";

export const DynamicPostiesBrochureHeader = dynamic<PostiesBrochureHeaderProps>(() =>
  import("scmp-app/components/posties/header/posties-brochure-header").then(
    module => module.PostiesBrochureHeader,
  ),
);

export const DynamicPostiesContentHeader = dynamic<PostiesContentHeaderProps>(() =>
  import("scmp-app/components/posties/header/posties-content-header").then(
    module => module.PostiesContentHeader,
  ),
);

export const DynamicHeader = dynamic<HeaderProps>(() =>
  import("scmp-app/components/header").then(module => module.Header),
);

export const DynamicHomeHeader = dynamic<HomeHeaderProps>(() =>
  import("scmp-app/components/home/header").then(module => module.HomeHeader),
);

export const DynamicPlusHeader = dynamic<PlusHeaderProps>(() =>
  import("scmp-app/components/plus/header").then(module => module.PlusHeader),
);
