import { useDebouncedCallback, useSyncedRef } from "@react-hookz/web";
import { useAtom } from "jotai";

import { useStickyElementObserver } from "scmp-app/lib/hooks";

import type { AppBarSticky } from "./atoms";
import { appBarStickyAtom } from "./atoms";

export const useAppBarSetStickyHeight = () => {
  const [appBarSticky, setAppBarSticky] = useAtom(appBarStickyAtom);
  const latestAppBarSticky = useSyncedRef(appBarSticky);
  const updateAppBarSticky = useDebouncedCallback(
    (appBarSticky: AppBarSticky) => setAppBarSticky(appBarSticky),
    [setAppBarSticky],
    100,
  );

  const { reference } = useStickyElementObserver(
    (rect, isSticky) => {
      // For supporting the content is hidden on the top (translateY)
      const height = isSticky ? rect.height + rect.top : 0;

      // Avoid update the atom if the values are the same
      if (
        latestAppBarSticky.current?.appBarHeight === rect.height &&
        latestAppBarSticky.current?.stickyHeight === height
      )
        return;
      updateAppBarSticky({
        appBarHeight: rect.height,
        stickyHeight: height,
      });
    },
    { supportScrollEvent: true },
  );

  return {
    reference,
  };
};
