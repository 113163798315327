import { useResizeObserver } from "@react-hookz/web";
import { useScroll, useThrottleEffect } from "ahooks";
import { useRef } from "react";

/**
 * This hooks is used for checking the target element is sticky or not and execute the callback when the element size is changed
 * @param callback
 * @param options If need to register extra events other than resize observer
 * @returns
 */
export const useStickyElementObserver = <T extends HTMLElement>(
  callback: (rect: DOMRect, isSticky: boolean) => void,
  options: {
    supportScrollEvent?: boolean;
  },
) => {
  const reference = useRef<T>(null);
  const scrollPosition = useScroll();

  const handleEventCallback = useRef(() => {
    if (!reference.current) return;
    const position = getComputedStyle(reference.current).position;
    const rect = reference.current.getBoundingClientRect();
    callback(rect, position === "sticky");
  });

  useThrottleEffect(
    () => {
      if (!options.supportScrollEvent) return;
      handleEventCallback.current();
    },
    [scrollPosition?.top],
    { wait: 200 },
  );

  useResizeObserver(reference, _ => {
    handleEventCallback.current();
  });

  return {
    reference,
  };
};
